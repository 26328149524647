:local {
  .console {
    display: flex;
    flex-direction: column;
  }

  .row {
    display: flex;
    border-color: #f0f0f0;
    border-bottom: 1px solid;
    font-family: monospace;
    padding: 3px;
  }

  .row.error {
    background-color: #fff0f0;
    color: #ff0000;
    border-color: #ffd6d6;
  }

  .row.log {
    background-color: #ffffff;
    border-color: #f0f0f0;
  }

  .row.info {
    border-color: #e2f3ff;
    background-color: #f7fcff;
  }

  .row.warn {
    background-color: #fffbe5;
    color: #5c3c00;
    border-color: #fff5c2;
  }

  .output {
    position: absolute;
    top: 0;
    bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 100%;
    max-width: 100%;
  }

  .timestamp {
    color: #808080;
    flex: 0 0 auto;
  }

  .consoleArgs {
    display: flex;
    flex-flow: wrap;
    white-space: pre-wrap;
    flex: auto;
    overflow-x: hidden;
    max-width: 100%;
  }

  .fileName {
    display: flex;
    flex: 0 1 auto;
  }

  .fileName > div {
    visibility: hidden;
  }

  .fileName:hover > div {
    visibility: visible;
  }

  .fileName > span {
    background-color: inherit;
    padding: 0 5px;
    color: #808080;
  }

  .copyAllButton {
    position: absolute;
    bottom: 0;
  }

  .copyAllButton > div {
    margin: 2px 4px;
  }
}
