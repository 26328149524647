:local {
  .app {
    color: #333;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    font-family: verdana, sans-serif;
    font-size: 12px;
    z-index: 2147483647;
    display: flex;
    flex-direction: column;
    height: 0;
  }

  .app dl {
    margin: 5px 0;
    display: flex;
    flex-flow: row wrap;
  }

  .app dt {
    font-weight: bold;
    padding-right: 5px;
    vertical-align: top;
    -ms-user-select: none;
    user-select: none;
    flex: 0 0 auto;
  }

  .app dt::after {
    content: ":";
  }

  .app dd {
    font-family: monospace;
    margin: 0;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1 1;
  }

  .icon > svg {
    width: 40px;
    height: 28px;
  }

  .icon {
    cursor: pointer;
    margin-left: 1px;
    position: absolute;
    bottom: -3px;
    box-shadow: 3px 1px 5px #aaa;
    height: 28px;
  }

  .icon:hover {
    margin-bottom: 3px;
  }

  .open .icon {
    bottom: unset;
    top: -3px;
    box-shadow: none;
  }

  .open .icon:hover {
    margin-top: 3px;
  }

  .tab {
    cursor: pointer;
    padding: 4px 14px;
    border-bottom: 2px solid transparent;
  }

  .tab:hover {
    background-color: #eaeaea;
  }

  .activeTab {
    border-color: #5c9a43;
  }

  .tabBar {
    background-color: #f3f3f3;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    padding-left: 45px;
    display: flex;
  }

  .content {
    position: relative;
    background-color: #fff;
    top: 0;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
  }

}
