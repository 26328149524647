:local {
  .network {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .list {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #bbb;
    overflow-x: auto;
    flex: 0 0 400px;
  }
}
