:local {
  .prompt {
    display: block;
    bottom: 0;
    left: 22px;
    right: 0;
    position: absolute;
  }

  .promptChar {
    font-family: monospace;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 1;
    padding-left: 5px;
    position: absolute;
  }

  .promptInput {
    font-family: monospace;
    padding-left: 20px;
    width: 100%;
    outline: none;
    border: 2px solid #f0f0f0;
    border-right: 0;
  }
}
