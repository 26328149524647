:local {
  .requestSummary {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 4px 6px;
    outline: none;
  }

  .requestSummary:hover {
    background-color: #F1F6FD;
  }

  .requestSummary.selected {
    background-color: #1A73E8;
    color: #fff;
  }

  .requestSummary > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status {
    text-align: center;
    width: 30px;
    flex: none;
  }

  .method {
    margin: 0 5px;
    flex-shrink: 0;
  }

  .url {
    direction: rtl;
  }
}
