:local {
  .resizer {
    position: absolute;
  }

  .heightMode {
    left: 0;
    right: 0;
    margin-top: -2px;
    height: 4px;
    cursor: ns-resize;
  }

  .widthMode {
    top: 0;
    bottom: 0;
    margin-left: -2px;
    width: 4px;
    cursor: ew-resize;
  }

  .resizer:hover,
  .resizer:active {
    background-color: #ddd;
  }
}
