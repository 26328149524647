:local {
  .label {
    margin-left: 10px;
  }

  .successMessage {
    position: absolute;
    color: #0C7A18;
    font-size: 1.2em;
    margin-top: -4px;
  }

  .tempTextArea {
    position: absolute;
    height: 0;
    width: 0;
    border: 0;
  }

  /**
   * 2 rectangles that make a "copy" icon
   */
  .copyButton {

    width: 13px;
    height: 14px;
    cursor: pointer;
    position: relative;

    div {
      position: absolute;
      border: 1px solid #999;
      background-color: #fff;
      border-radius: 2px;
      width: 7px;
      height: 10px;
      box-sizing: content-box;
    }

    .back {
      border-width: 1px 1px 0 0;
      margin-left: 4px;
    }

    .front {
      margin-top: 2px;
    }
  }
}
