:local {

  .success {
    color: #5c9a43;
  }

  .error {
    color: #f00;
  }
}
